<template>
    <footer class="footer">
        <nav class="footer__main">
            <ItemGrid
                class="item-grid--mb"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="3"
                data-columns-lg="3"
                data-columns-xl="3"
            >
                <div class="item-grid__item">
                    <h4 class="footer__title">{{ leftHeader }}</h4>
                    <div
                        v-for="(link, index) in leftLinks"
                        :key="index"
                    >
                        <NavLink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </NavLink>
                    </div>
                </div>

                <div class="item-grid__item">
                    <h4 class="footer__title">{{ middleHeader }}</h4>
                    <div
                        v-for="(link, index) in middleLinks"
                        :key="index"
                    >
                        <NavLink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </NavLink>
                    </div>
                </div>

                <div class="item-grid__item">
                    <h4 class="footer__title">{{ rightHeader }}</h4>
                    <div
                        v-for="(link, index) in rightLinks"
                        :key="index"
                    >
                        <NavLink
                            :type="mapType(link.__typename)"
                            :target="mapTarget(link.targetBlank)"
                            :url="mapURI(link)"
                            :icon="link.icon"
                            class="footer__main__link"
                        >
                            {{ link.titel }}
                        </NavLink>
                    </div>
                </div>
            </ItemGrid>
        </nav>

        <div class="footer__extra">
            <ItemGrid
                v-if="isBglf"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="1"
                data-columns-lg="3"
                data-columns-xl="3"
            >
                <i18n-t
                    keypath="footer.bglf"
                    tag="p"
                    class="item-grid__item-double"
                >
                    <template #doen>
                        <a
                            href="https://www.doen.nl"
                            target="_blank"
                        >
                            {{ t('footer.label_doen') }}
                        </a>
                    </template>

                    <template #vriendenloterij>
                        <a
                            href="https://www.vriendenloterij.nl"
                            target="_blank"
                        >
                            {{ t('footer.label_vriendenloterij') }}
                        </a>
                    </template>
                </i18n-t>

                <div
                    v-if="locale === 'nl'"
                    class="item-grid__item-single"
                >
                    <NuxtLink
                        :to="{ path: '/over-het-fonds' }"
                        class="footer__link link--clean"
                    >
                        <img
                            class="footer__extra__doen"
                            src="~/assets/img/logos/logo-doen-original.svg"
                            alt="Stichting DOEN"
                        >
                    </NuxtLink>
                </div>
            </ItemGrid>
            <div v-else-if="isParticipaties">
                {{ t('footer.participaties') }}
            </div>
            <ItemGrid
                v-else-if="isDoen"
                data-columns-xs="1"
                data-columns-sm="1"
                data-columns-md="1"
                data-columns-lg="2"
                data-columns-xl="2"
            >
                <div class="item-grid__item-single">
                    <NuxtLink
                        :to="locale === 'nl' ? '/over-ons/goede-doelen-loterijen' : '/en/about-us/charity-lotteries'"
                        class="link--clean"
                    >
                        <span class="footer__extra__donors-text">
                            {{ t('footer.doen_donors') }}
                        </span>
                        <div class="footer__extra__donors">
                            <img
                                src="~/assets/img/logos/logo-npl-box.png"
                                alt="De Nationale Postcode Loterij"
                                width="642"
                                height="424"
                                loading="lazy"
                                decoding="async"
                                fetchpriority="low"
                            >
                            <img
                                src="~/assets/img/logos/logo-vl-box.png"
                                alt="De Vriendenloterij"
                                width="642"
                                height="420"
                                loading="lazy"
                                decoding="async"
                                fetchpriority="low"
                            >
                        </div>
                    </NuxtLink>
                </div>
                <div
                    v-if="locale === 'nl'"
                    class="item-grid__item-single footer__icons"
                >
                    <NuxtLink
                        :to="{ path: '/fin-normen-goed-bestuur' }"
                        class="footer__link link--clean"
                    >
                        <img
                            class="footer__extra__fin"
                            src="~/assets/img/logos/logo-fin-goedbestuur.png"
                            alt=""
                        >
                    </NuxtLink>
                    <NuxtLink
                        :to="{ path: '/over-ons/doen-is-een-cbf-erkend-goed-doel' }"
                        class="footer__link link--clean"
                    >
                        <span class="footer__extra__cbf-text">{{ t('footer.doen_cbf') }}</span>
                        <img
                            class="footer__extra__cbf"
                            src="~/assets/img/logos/logo-cbf.png"
                            alt=""
                        >
                    </NuxtLink>
                </div>
            </ItemGrid>
        </div>

        <nav class="footer__bottom">
            <div class="footer__bottom__container">
                <div
                    v-for="(link, index) in bottomLinks"
                    :key="index"
                    class="footer__bottom__link"
                >
                    <NavLink
                        :type="mapType(link.__typename)"
                        :target="mapTarget(link.targetBlank)"
                        :url="mapURI(link)"
                        :icon="link.icon"
                    >
                        {{ link.titel }}
                    </NavLink>
                </div>
            </div>
        </nav>
    </footer>
</template>

<script setup>
import FooterNavigation from '~/graphql/queries/FooterNavigation.graphql';

const { locale, t } = useI18n();
const { isBglf, isDoen, isParticipaties } = useMultisite();

const { variables: defaultVariables } = useDefaultVariables();

const variables = computed(() => {
    return {
        ...defaultVariables.value,
        uri: null
    };
});

const { data } = await useAsyncQuery({
    query: FooterNavigation,
    variables
});

const footer = computed(() => data.value?.globalSet ?? null);

const leftHeader = computed(() => footer.value?.footerHeaderLeft ?? '');
const leftLinks = computed(() => footer.value?.footerLeft?.[0]?.linkBlock ?? []);

const middleHeader = computed(() => footer.value?.footerHeaderMiddle ?? '');
const middleLinks = computed(() => footer.value?.footerMiddle?.[0]?.linkBlock ?? []);

const rightHeader = computed(() => footer.value?.footerHeaderRight ?? '');
const rightLinks = computed(() => footer.value?.footerRight?.[0]?.linkBlock ?? []);

const bottomLinks = computed(() => footer.value?.footerBottom?.[0]?.linkBlock ?? []);
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
};
</script>

<style src="./TheFooter.less" lang="less" />
